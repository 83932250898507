import * as React from "react";
import GetStartedForm from "containers/GetStartedForm";
import HeroAlt from "containers/HeroAlt";
import Layout from "common/components/Layout";
import Seo from "components/seo";

const GetStartedPage = ({ location }) => {
  return (
    <Layout location={location.pathname}>
      <Seo title="Get Started Today" />
      <HeroAlt
        title="Get Started Today"
        text="Tell us a little bit about your business and we will do the rest."
      />
      <GetStartedForm />
    </Layout>
  );
};

export default GetStartedPage;
